<template>
    <div v-if="!!value && !!beta_tests.length && is_at_least_administrator" class="beta-testers-input">
        <slot></slot>
        <form-group
            v-bind="form_group_props"
        >
            <template v-slot:read_only>
                <slot name="read_only">
                    <label
                        v-if="has_label"
                        class="form-label"
                        :for="identifier"
                    >
                        <slot name="label"></slot>
                    </label>
                    <label
                        class="form-label disabled-field"
                    >
                        {{ display_value }}
                    </label>
                </slot>
            </template>
            <template v-slot:label><slot name="label"></slot></template>
            <template v-slot:hint><slot name="hint"></slot></template>

            <div class="columns">
                <div
                    v-for="beta_test in beta_tests"
                    :key="beta_test.id"
                    class="column"
                >
                    <default-toggle-input
                        :id="beta_test.id"
                        type="checkbox"
                        :name="name"
                        :value="is_beta_testing(value, beta_test.id)"
                        :size="size"
                        :disabled="!editable && (!required || (value.length > 1))"
                        @input="toggle_beta_test(beta_test.id)"
                    >
                        {{ beta_test.name }}
                    </default-toggle-input>
                </div>
            </div>
        </form-group>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import FormGroup from "./FormGroup"
import DefaultToggleInput from "./DefaultToggleInput"

export default {
    name: "BetaTestersInput",
    mixins: [is_nibnut_component, profile_utilities, misc_utilities],
    components: {
        FormGroup,
        DefaultToggleInput
    },
    mounted () {
        this.refresh()
    },
    watch: {
        profile_id: "refresh"
    },
    methods: {
        refresh () {
            if(this.profile_id) {
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "beta_test",
                        query: {
                            fields: ["ns::beta_test;fieldset::picker"]
                        }
                    }
                ).then(response => {
                    this.current_records_ids = response.record_ids || []
                })
            }
        },
        toggle_beta_test (beta_test_id) {
            const value = [...this.value]
            const index = value.indexOf(beta_test_id)
            if(index >= 0) {
                if(!this.required || (value.length > 1)) value.splice(index, 1)
            } else value.push(beta_test_id)
            this.$emit("input", value, this.name)
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        has_label () {
            return this.has_slot("label")
        },
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        },
        display_value () {
            const beta_tests = this.beta_tests
            return this.value.map(beta_test_id => {
                const beta_test = beta_tests.find(beta_test => beta_test.id === beta_test_id)
                return beta_test ? beta_test.name : beta_test_id
            }).join(", ")
        },
        beta_tests () {
            return this.entity_records("beta_test", this.current_records_ids)
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        value: {
            type: Array,
            default () {
                return []
            }
        },
        size: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        required: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            name: "active_beta_tests",
            current_records_ids: []
        }
    }
}
</script>
