<template>
    <div>
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <div class="modal-header">
            <dismiss-link
                v-if="dismissable"
                id="first_focusable"
                @click="dismiss"
                class="btn btn-clear float-right"
            />
            <div class="modal-title h5">
                {{ $root.translate("Login") }}
            </div>
        </div>
        <div class="modal-body">
            <base-form
                :has-required-fields="true"
                @submit.prevent.stop="login"
            >
                <form-input
                    :id="dismissable ? 'email' : 'first_focusable'"
                    ref="first_field"
                    v-model="email"
                    name="email"
                    type="email"
                    :error="has_error('email')"
                    :required="true"
                    :maybe-autofilled="true"
                    @input="update_email"
                    @blur="update_email"
                >
                    <template v-slot:label v-if="!labelLess">{{ $root.translate("Email") }}</template>
                </form-input>
                <password-input
                    id="password"
                    v-model="password"
                    type="password"
                    name="password"
                    :error="has_error('password')"
                    :required="true"
                    @blur="password=$event"
                >
                    <template v-slot:label v-if="!labelLess">{{ $root.translate("Password") }}</template>
                </password-input>
                <div class="text-center">
                    <default-button
                        flavor="link"
                        size="sm"
                        @click.prevent="goto('password')"
                    >
                        {{ $root.translate("Shoot! I forgot my password...") }}
                    </default-button>
                </div>

                <div class="text-center mt-8">
                    <feedback-button
                        :id="can_signup ? false : 'last_focusable'"
                        type="submit"
                        :status="feedback_status"
                        :waiting="loading"
                        :single-use="false"
                        color="primary"
                    >
                        <template v-slot:message>
                            <div
                                :class="{ 'text-success': (feedback_status === true), 'text-error': (feedback_status === false) }"
                            >
                                {{ feedback_message }}
                            </div>
                        </template>
                        {{ $root.translate("Login") }}
                    </feedback-button>
                </div>
            </base-form>
        </div>
        <div v-if="can_signup" class="modal-footer">
            <default-button
                id="last_focusable"
                flavor="link"
                size="sm"
                @click.prevent="goto('signup')"
            >
                {{ $root.translate("I'm new here, I want to signup") }}
            </default-button>
        </div>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>
    </div>
</template>

<script>
import is_panel from "./IsPanel"
import DismissLink from "@/nibnut/components/ModalDialog/DismissLink"
import BaseForm from "@/nibnut/components/BaseForm"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import PasswordInput from "@/nibnut/components/Inputs/PasswordInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import FeedbackButton from "@/nibnut/components/Buttons/FeedbackButton"

export default {
    mixins: [is_panel],
    components: {
        DismissLink,
        BaseForm,
        FormInput,
        PasswordInput,
        DefaultButton,
        FeedbackButton
    },
    methods: {
        login () {
            setTimeout(() => {
                this.loading = true
                this.clear_all_errors()
                this.$store.dispatch("LOGIN", {
                    email: this.email,
                    password: encodeURIComponent(this.password)
                }).then(() => {
                    this.$emit("input", "")
                    this.password = ""
                    this.$store.dispatch("UNREQUEST_LOGIN")
                }).catch(this.receive_error).then(() => {
                    this.loading = false
                })
            }, this.autofill_timeout) // need a timeout slightly longer than input field's debounce. Otherwise, Enter key happens before this.field is updated!
        }
    },
    data () {
        return {
            password: ""
        }
    }
}
</script>
