import Page from "@/nibnut/components/Page"

const is_page = {
    components: {
        Page
    },
    methods: {
        statused (status) {
            this.page_status = status
            this.waiting = false
            if(!this.$route.meta.login_required) this.$store.dispatch("BOOTSRAPPED")
            else {
                this.$store.dispatch("BOOTSRAPPED")
                if(this.page_status !== 307) this.$store.dispatch("BOOTSRAPPED")
                else if((!this.login_request || !this.login_request.panel_id) && !this.profile_id) this.$store.dispatch("REQUEST_LOGIN", { panel_id: true })
            }
        },
        page_autoscroll () {
            if(!!this.$route.meta && !!this.$route.meta.scroll_position && (this.$route.meta.scroll_position !== true)) {
                setTimeout(() => {
                    window.scrollTo({ top: this.$route.meta.scroll_position.y, left: this.$route.meta.scroll_position.x })
                }, 250)
            }
        }
    },
    data () {
        return {
            page_status: 200,
            waiting: true
        }
    }
}

export default is_page
