<template>
    <div
        :class="{ 'has-icon-left': has_left_icon, 'has-icon-right': has_right_icon }"
        class="input-group"
    >
        <open-icon
            v-if="has_left_icon"
            :library="glyphLibrary"
            :glyph="glyph"
            :size="glyphSize"
            @click.native="click_icon"
            class="form-icon"
        />
        <slot name="left_addon"></slot>
        <slot></slot>
        <open-icon
            v-if="has_right_icon"
            :library="glyphLibrary"
            :glyph="glyph"
            :size="glyphSize"
            @click.native="click_icon"
            class="form-icon"
        />
        <slot name="right_addon"></slot>
    </div>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "InputGroup",
    mixins: [is_nibnut_component],
    components: {
        OpenIcon
    },
    methods: {
        click_icon (event) {
            this.$emit("click", event)
        }
    },
    computed: {
        has_left_icon () {
            return !!this.glyph && (this.glyphPosition === "left")
        },
        has_right_icon () {
            return !!this.glyph && (this.glyphPosition === "right")
        },
        has_left_addon () {
            return !this.has_left_icon && !!this.$slots.left_addon
        },
        has_right_addon () {
            return !this.has_right_icon && !!this.$slots.right_addon
        }
    },
    props: {
        glyph: {
            type: String,
            default: ""
        },
        glyphLibrary: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(las|lab)$/i),
            default: "las"
        },
        glyphPosition: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(left|right)$/i),
            default: "right"
        },
        glyphSize: {
            type: String,
            validator: prop => {
                return !prop || !!prop.match(/^(xs|1x|lg|2x|3x|4x|5x|6x|7x|8x|9x|10x)$/i)
            },
            default: "1x"
        }
    }
}
</script>
