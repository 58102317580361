<template>
    <span
        :class="{ tooltip: !!definition }"
        class="defined-term" :data-tooltip="definition"
    >
        <span>
            <slot></slot>
        </span>
    </span>
</template>

<script>
export default {
    name: "DefinedTerm",
    props: {
        definition: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.tooltip.defined-term {
    position: relative;
    display: inline-block;
    cursor: help;

    & > span::after {
        position: absolute;
        display: inline-block;
        content: " ";
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px dashed $gray-color;
    }
    @media (max-width: $size-sm) {
        &,
        &.tooltip-top,
        &.tooltip-right,
        &.tooltip-bottom,
        &.tooltip-left {
            top: 0;
            left: 0;
            transform: none;
        }
    }
}
</style>
